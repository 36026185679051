<template>
  <div>
    <div class="main-header">
      <div class="title">用户管理</div>
    </div>
    <div class="main-main">
      <a-card>
        <div class="search-card">
          <div class="search-card">
            <div class="search-row">
              <div class="search-option">
                <span class="label">姓名</span>
                <a-input
                  placeholder="请输入姓名"
                  v-model="searchNickName"
                ></a-input>
              </div>
              <div class="search-option">
                <span class="label">手机号</span>
                <a-input
                  placeholder="请输入手机号"
                  v-model="searchPhone"
                  type="number"
                ></a-input>
              </div>
              <div class="search-option">
                <span class="label">用户类型</span>
                <a-select
                  :allowClear="true"
                  class="search-select"
                  placeholder="请输入用户类型"
                  :options="allUserType"
                  v-model="searchUserType"
                >
                </a-select>
              </div>
              <div class="search-option">
                <span class="label">所属组织</span>
                <a-tree-select
                  allowClear
                  class="search-select"
                  v-model="searchGroupId"
                  :tree-data="groupTree"
                  placeholder="请选择所属组织"
                >
                </a-tree-select>
              </div>
              <div class="empty">
                <a-button type="primary" @click="searchByOptions" icon="search"
                  >搜索</a-button
                >
              </div>
            </div>
          </div>
        </div>
        <div class="optionsAndPagination"></div>
        <div class="actions">
          <div class="btns" style="gap: 10px">
            <a-button icon="plus-circle" @click="addUser">新增</a-button>
            <a-button icon="minus-circle" @click="delUser">删除</a-button>
            <a-button @click="showAddUserFromXlsxModal">
              <a-icon type="upload" />导入用户信息</a-button
            >
            <a-button icon="download">
              导出用户信息
              <vue-excel-xlsx
                class="excel-btn"
                :columns="excelColumns"
                sheetname="用户管理"
                filename="用户管理"
                :data="excelData"
              ></vue-excel-xlsx>
            </a-button>
          </div>
          <Pagination
            @refresh="refresh"
            @pageChange="pageChange"
            @pageSizeChange="pageSizeChange"
            :total="total"
            :page="searchOptions.page"
          ></Pagination>
        </div>
        <div>
          <a-table
            size="middle"
            :loading="tableLoading"
            :data-source="tableData"
            :columns="tableColumns"
            :pagination="false"
            :row-selection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
            :scroll="{ x: 10 }"
          >
            <template slot="timeMoney" slot-scope="timeMoney, row">
              <a @click="checktimeMoney(row)">{{ timeMoney }}</a>
            </template>
            <template slot="totalSeconds" slot-scope="totalSeconds">
              <span>{{ totalSeconds }}</span>
            </template>
            <template slot="userType" slot-scope="userType">
              {{ userType == 1 ? "长者" : userType == 2 ? "志愿者" : "管理员" }}
            </template>
            <template slot="sex" slot-scope="sex">
              {{ formatSex(sex) }}
            </template>
            <template slot="action" slot-scope="row">
              <div style="display: flex; gap: 10px; justify-content: center">
                <a @click="editUser(row)">编辑</a>
                <a @click="showQrCode(row)">查看二维码</a>
              </div>
            </template>
          </a-table>
        </div>
      </a-card>
    </div>
    <a-modal
      v-model="timeMoneyModal"
      :footer="null"
      :closable="false"
      :title="usertimeMoneyExcelTitle"
    >
      <div class="time-coin-info">
        <div class="user-name">用户姓名：{{ nowuserName }}</div>
        <div class="time-coin">时间币余额：{{ nowTimeMoney }}</div>
        <div class="total-timeMoney">
          历史获取时间币：{{ nowTotalTimeMoney }}
        </div>
      </div>
      <div>
        <a-table
          size="small"
          :loading="smallTableLoading"
          :data-source="timeMoneyTableData"
          :columns="timeMoneyTableColumns"
          :pagination="false"
          :scroll="{ y: 230 }"
        >
          <template slot="time" slot-scope="time">
            {{ $dayjs(time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
          <template slot="timeMoney" slot-scope="timeMoney, row">
            <span :class="row.isIncome == 0 ? 'red' : 'green'">{{
              timeMoney
            }}</span>
          </template>
        </a-table>
      </div>
      <div class="modal-btn">
        <a-button icon="download" type="primary">
          导出名单
          <vue-excel-xlsx
            class="excel-btn"
            :columns="timeMoneyExcelColumns"
            :sheetname="usertimeMoneyExcelTitle"
            :filename="usertimeMoneyExcelTitle"
            :data="timeMoneyTableData"
          >
          </vue-excel-xlsx>
        </a-button>
      </div>
    </a-modal>
    <a-modal
      v-model="qrCodeModal"
      :footer="null"
      :closable="false"
      :width="200"
      centered
    >
      <div id="qrcode" ref="qrcode" class="qrcode"></div>
    </a-modal>
    <a-modal
      v-model="newOrEditModal"
      :title="newOrEditModalTitle"
      :footer="null"
      :closable="false"
      :width="900"
    >
      <a-form-model
        :model="form"
        class="activity-form"
        :labelCol="{ span: 6 }"
        :wrapperCol="{ span: 14 }"
        :rules="rules"
        ref="ruleForm"
      >
        <a-form-model-item label="姓名" prop="nickName">
          <a-input v-model="form.nickName"></a-input>
        </a-form-model-item>
        <a-form-model-item label="证件号">
          <a-input v-model="form.idCard"></a-input>
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="phone">
          <a-input v-model="form.phone" type="number"></a-input>
        </a-form-model-item>
        <a-form-model-item label="性别" prop="sex">
          <a-select :options="allSex" v-model="form.sex"></a-select>
        </a-form-model-item>
        <a-form-model-item label="用户类型" prop="userType">
          <a-select :options="allUserType" v-model="form.userType"></a-select>
        </a-form-model-item>
        <a-form-model-item label="紧急联系方式" prop="familyTel">
          <a-input v-model="form.familyTel" type="number"></a-input>
        </a-form-model-item>
        <a-form-model-item label="生日" prop="birthday">
          <a-date-picker v-model="form.birthday" format="YYYY-MM-DD" style="width: 100%" />
        </a-form-model-item>
        <!-- <a-form-model-item label="家庭住址" prop="address">
          <a-input v-model="form.address"></a-input>
        </a-form-model-item> -->
        <a-form-model-item label="是否党员" prop="isParty">
          <a-select :options="partyOptions" v-model="form.isParty"></a-select>
        </a-form-model-item>
        <a-form-model-item label="所属组织" prop="groupId">
          <a-tree-select
            v-model="form.groupId"
            :tree-data="groupTree"
          ></a-tree-select>
        </a-form-model-item>
        <a-form-model-item label="一体机卡号" prop="cardId">
          <a-input v-model="form.cardId"></a-input>
        </a-form-model-item>
      </a-form-model>
      <div class="modal-btn">
        <a-button @click="submit" type="primary" :disabld="submitDisable"
          >保存提交</a-button
        >
      </div>
    </a-modal>
    <a-modal
      title="导入用户"
      :footer="null"
      :closable="false"
      v-model="addUserFromXlsxModal"
    >
      <div class="modal-container">
        <div class="modal-label">所属组织：</div>
        <div class="modal-input">
          <a-tree-select
            style="width: 200px"
            v-model="addUsersGroupId"
            :tree-data="groupTree"
            placeholder="请选择所属组织"
          >
          </a-tree-select>
        </div>
      </div>
      <div class="modal-container">
        <div class="modal-label">EXCEL文件：</div>
        <div class="modal-upload">
          <a-upload
            name="file"
            accept=".xls,.xlsx"
            :fileList="fileList"
            @change="handleChange"
            :customRequest="doNothing"
          >
            <a-button> <a-icon type="file-excel" />选择文件</a-button>
          </a-upload>
        </div>
      </div>
      <a-divider orientation="left">注意事项</a-divider>
      <div>提示： 1、导入前请先下载导入模板，确认导入格式；</div>
      <div style="text-indent: 3rem">
        2、用户姓名、身份证、手机号不能为空且手机号不能重复；
      </div>
      <div style="text-indent: 3rem">3、导入数据过多时，请耐心等待；</div>
      <div style="text-indent: 3rem">
        4、每次导入不得大于500条，文件大小不超2M;
      </div>
      <div class="modal-btn">
        <a-button
          type="primary"
          icon="upload"
          @click="inputUser"
          style="margin-right: 20px"
          >确认导入</a-button
        >
        <a-button icon="download" @click="downMb">下载模版</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import XLSX from "xlsx";
import QRCode from "qrcodejs2";
import {
  addUser,
  delUser,
  getUserList,
  editUser,
  getTimeMoneyRecord,
  importUser,
} from "@/network/api";
import Pagination from "../components/Pagination";
import { mapState } from "vuex";
import moment from 'moment';
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      allTableData: [],
      smallTableLoading: false,
      nowuserName: "",
      nowTimeMoney: "",
      nowTotalTimeMoney: "",
      submitDisable: false,
      searchNickName: "",
      searchPhone: "",
      searchUserType: null,
      searchGroupId: null,
      inputData: [],
      addUsersGroupId: "",
      addUserFromXlsxModal: false,
      fileJson: null,
      fileList: [],
      usertimeMoneyExcelTitle: "",
      total: 0,
      form: {},
      newOrEditModal: false,
      newOrEditModalTitle: "",
      newqrcode: null,
      qrCodeModal: false,
      timeMoneyModal: false,
      headers: {
        authorization: "authorization-text",
      },
      selectedRowList: [],
      selectedRowKeys: [],
      tableLoading: false,
      tableData: [],
      timeMoneyTableData: [],
      searchOptions: {
        page: 1,
        pageSize: 20,
      },
      allUserType: [
        { title: "长者", value: 1 },
        { title: "志愿者", value: 2 },
        { title: "管理员", value: 3 },
      ],
      allSex: [
        { title: "保密", value: 0 },
        { title: "男", value: 1 },
        { title: "女", value: 2 },
      ],
      partyOptions: [
        { title: "否", value: 0 },
        { title: "是", value: 1 },
      ],
    };
  },
  methods: {
    formatHour(val) {
      if (val == 0) {
        return 0;
      }
      return ((val / 3600).toFixed(1) * 100) / 100;
    },
    downMb() {
      const a = document.createElement("a");
      a.download = "用户信息导入模版.xls";
      a.href = "/用户信息导入模版.xls";
      document.body.appendChild(a);
      a.click();
      a.remove();
    },
    async getTimeMoneyRecord(id) {
      this.smallTableLoading = true;
      const { data } = await getTimeMoneyRecord({ userId: id });
      this.timeMoneyTableData = data.content.map((item) => {
        item.admin = item.lastModifiedBy;
        item.name = item.recordName;
        item.time = item.recordTime;
        item.timeMoneyCount = item.timeMoneyCount ? item.timeMoneyCount : 0;

        item.timeMoney = item.isIncome
          ? "-" + item.timeMoneyCount
          : "+" + item.timeMoneyCount;
        return item;
      });
      this.smallTableLoading = false;
    },
    async getUserList(
      params = {
        page: 1,
        pageSize: 20,
        userType: this.searchOptions.userType,
      }
    ) {
      this.tableLoading = true;
      this.selectedRowList = [];
      this.selectedRowKeys = [];
      const { data } = await getUserList(params);
      this.tableData = data.content.map((item) => {
        item.totalSeconds = this.formatHour(item.totalSeconds);
        return item;
      });
      this.total = data.total;
      this.tableLoading = false;
      const { data:alldata } = await getUserList({
        ...params,
        page: 1,
        pageSize: 9999,
      });
      this.allTableData = alldata.content.map((item) => {
        item.totalSeconds = this.formatHour(item.totalSeconds);
        return item;
      });
      // console.log(this.allTableData);
      // console.log(this.tableData);
    },
    inputUser() {
      if (!this.addUsersGroupId) {
        return this.$message.error("请选择所属组织！");
      }
      if (!this.inputData.length) {
        return this.$message.error("请导入用户数据！");
      }
      console.log(this.inputData);
      importUser({
        groupId: this.addUsersGroupId,
        list: this.inputData,
      }).then((res) => {
        if (res.status == 0) {
          this.$message.success("导入成功！");
          this.addUserFromXlsxModal = false;
          this.getUserList();
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    doNothing() {},
    showAddUserFromXlsxModal() {
      this.inputData = [];
      this.addUsersGroupId = null;
      this.fileList = [];
      this.addUserFromXlsxModal = true;
    },
    async submit() {
      console.log(this.addForm);
      // const { data } = await addUser(this.addForm);
    },
    pageChange(page, pageSize) {
      this.searchOptions.page = page;
      this.getUserList(this.searchOptions);
    },
    pageSizeChange(page, pageSize) {
      this.searchOptions.page = 1;
      this.searchOptions.pageSize = pageSize;
      this.getUserList(this.searchOptions);
    },
    refresh() {
      this.getUserList();
    },
    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const params = {...this.form}
          if (params.birthday && params.birthday instanceof moment) {
            params.birthday = params.birthday.format('YYYY-MM-DD') + ' 00:00:00'
          }
          // params.birthday && (params.birthday = params.birthday.format('YYYY-MM-DD') + ' 00:00:00')
          this.submitDisable = true;
          if (this.newOrEditModalTitle == "新增用户") {
            addUser(params).then((res) => {
              if (res.status == 0) {
                this.$message.success("新增成功！");
                this.newOrEditModal = false;
                this.searchOptions.page = 1;
                this.getUserList(this.searchOptions);
              } else {
                this.$message.success("新增失败！");
                this.submitDisable = false;
              }
            });
          } else {
            editUser(params).then((res) => {
              if (res.status == 0) {
                this.$message.success("编辑成功！");
                this.newOrEditModal = false;
                this.getUserList(this.searchOptions);
              } else {
                this.$message.success("编辑失败！");
                this.submitDisable = false;
              }
            });
          }
        }
      });
    },
    showQrCode(row) {
      console.log(row);
      this.qrCodeModal = true;
      this.$nextTick(() => {
        if (!this.newqrcode) {
          this.newqrcode = new QRCode("qrcode", {
            width: 150, //图像宽度
            height: 150, //图像高度
            colorDark: "#000000", //前景色
            colorLight: "#ffffff", //背景色
            typeNumber: 4,
            correctLevel: QRCode.CorrectLevel.H, //容错级别 容错级别有：（1）QRCode.CorrectLevel.L （2）QRCode.CorrectLevel.M （3）QRCode.CorrectLevel.Q （4）QRCode.CorrectLevel.H
          });
        }
        this.newqrcode.clear(); //清除二维码
        this.newqrcode.makeCode(row.id + "");
      });
    },
    formatTime(e) {
      return this.$dayjs(e).format("YYYY-MM-DD HH:mm:ss");
    },
    checktimeMoney(row) {
      this.nowuserName = row.nickName;
      this.nowTimeMoney = row.timeMoney;
      this.nowTotalTimeMoney = row.totalTimeMoney;
      this.getTimeMoneyRecord(row.id);
      this.usertimeMoneyExcelTitle = row.nickName + "时间币流水列表";
      this.timeMoneyModal = true;
    },
    handleChange(info) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);
      fileList[0].status = "done";
      this.fileList = fileList;
      const fileReader = new FileReader();
      var errFlag = false;
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const workbook = XLSX.read(data, {
            type: "binary",
          });
          const wsname = workbook.SheetNames[0]; //取第一张表
          var ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            range: 2,
            // defval: "null",
          }); //生成json表格内容
          for (let i = 0; i < ws.length; i++) {
            if (
              !ws[i]["手机号*(不能重复)"] ||
              !ws[i]["姓名*"] ||
              !ws[i]["用户类型*(长者/志愿者)"]
            ) {
              errFlag = true;
              return this.$message.error("文件格式有误！");
            }
            // if (
            //   !ws[i]["手机号*(不能重复)"] ||
            //   !ws[i]["姓名*"] ||
            //   !ws[i]["身份证号码*"] ||
            //   !ws[i]["用户类型*(长者/志愿者)"]
            // ) {
            //   errFlag = true;
            //   return this.$message.error("文件格式有误！");
            // }
            ws[i]["phone"] = ws[i]["手机号*(不能重复)"];
            ws[i]["nickName"] = ws[i]["姓名*"];
            ws[i]["idCard"] = ws[i]["身份证号码*"];
            ws[i]["userType"] =
              ws[i]["用户类型*(长者/志愿者)"] == "长者" ? 1 : 2;
            if (ws[i]["生日(YYYY-MM-DD)"]) {
              ws[i]["birthday"] = ws[i]["生日(YYYY-MM-DD)"] + ' 00:00:00'
            }
            // ws[i]["address"] = ws[i]["家庭住址"];
            ws[i]["isParty"] = ws[i]['是否党员'] ? (ws[i]["是否党员"] == "是" ? 1 : ws[i]["是否党员"] == "否" ? 0 : 0 ) : 0
            ws[i]["familyTel"] = ws[i]["紧急联系方式"];
            ws[i]["sex"] = ws[i]["性别(不填默认保密)"]
              ? ws[i]["性别(不填默认保密)"] == "男"
                ? 1
                : ws[i]["性别(不填默认保密)"] == "女"
                ? 2
                : 0
              : 0;
            ws[i]["cardId"] = ws[i]["一体机卡号"];
            delete ws[i]["手机号*(不能重复)"];
            delete ws[i]["姓名*"];
            delete ws[i]["身份证号码*"];
            delete ws[i]["用户类型*(长者/志愿者)"];
            delete ws[i]["生日(YYYY-MM-DD)"];
            // delete ws[i]["家庭住址"];
            delete ws[i]["是否党员"];
            delete ws[i]["紧急联系方式"];
            delete ws[i]["性别(不填默认保密)"];
            delete ws[i]["一体机卡号"]
          }
          this.inputData = ws;
        } catch (e) {
          return false;
        }
      };
      fileReader.readAsBinaryString(this.fileList[0].originFileObj);
    },
    addUser() {
      this.form = {};
      this.newOrEditModal = true;
      this.newOrEditModalTitle = "新增用户";
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });
    },
    editUser(row) {
      this.form = {
        ...row,
      };
      this.newOrEditModal = true;
      this.submitDisable = false;
      this.newOrEditModalTitle = "编辑用户";
      this.$nextTick(() => {
        this.$refs.ruleForm.clearValidate();
      });
    },
    delUser() {
      this.$confirm({
        title: "确定要删除用户吗？",
        onOk: () => {
          return new Promise((resolve, reject) => {
            const delUserIds = this.selectedRowList.map((item) => item.id);
            delUser({ ids: delUserIds }).then((res) => {
              if (res.status == 0) {
                this.searchOptions.page = 1;
                this.getUserList(this.searchOptions);
                this.$message.success("删除成功！");
                resolve();
              } else {
                reject();
              }
            });
          }).catch(() => {
            this.$message.error("删除失败！");
          });
        },
      });
    },
    formatSex(e) {
      if (e == 0) {
        return "保密";
      }
      if (e == 1) {
        return "男";
      }
      if (e == 2) {
        return "女";
      }
    },
    formatUserType(e) {
      if (e == 1) {
        return "长者";
      }
      if (e == 2) {
        return "志愿者";
      }
      if (e == 3) {
        return "管理员";
      }
      return "未知";
    },
    searchByOptions() {
      this.searchOptions.phone = this.searchPhone;
      this.searchOptions.nickName = this.searchNickName;
      this.searchOptions.userType = this.searchUserType;
      this.searchOptions.groupId = this.searchGroupId;
      this.searchOptions.page = 1;
      this.getUserList(this.searchOptions);
    },
    onSelectChange(e) {
      this.selectedRowKeys = e;
      this.selectedRowList = [];
      this.selectedRowKeys.map((item) => {
        this.selectedRowList.push(this.tableData[item]);
      });
    },
  },

  created() {
    this.getUserList();
  },
  computed: {
    ...mapState(["groupTree"]),
    rules() {
      return {
        nickName: [
          {
            required: true,
            message: "请输入姓名！",
            trigger: "blur",
          },
        ],
        idCard: [
          {
            required: true,
            message: "请输入证件号！",
            trigger: "blur",
          },
        ],
        userType: [
          {
            required: true,
            message: "请选择用户类型！",
            trigger: "blur",
          },
        ],
        sex: [
          {
            required: true,
            message: "请选择性别！",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号！",
            trigger: "blur",
          },
        ],
        // familyTel: [
        //   {
        //     required: true,
        //     message: "请输入紧急联系方式！",
        //     trigger: "blur",
        //   },
        // ],
        // address: [
        //   {
        //     required: true,
        //     message: "请输入家庭住址！",
        //     trigger: "blur",
        //   },
        // ],
        // groupId: [
        //   {
        //     required: true,
        //     message: "请输入所属组织！",
        //     trigger: "blur",
        //   },
        // ],
      };
    },
    timeMoneyExcelColumns() {
      return [
        { label: "活动/商品名称", field: "name" },
        { label: "操作时间", field: "time" },
        { label: "时间币", field: "timeMoney" },
        { label: "管理员", field: "admin" },
      ];
    },
    excelColumns() {
      return [
        { label: "id", field: "id" },
        { label: "用户名", field: "nickName" },
        { label: "手机号", field: "phone" },
        {
          label: "用户类型",
          field: "userType",
          dataFormat: this.formatUserType,
        },
        { label: "性别", field: "sex", dataFormat: this.formatSex },
        { label: "时间币余额", field: "timeMoney" },
        { label: "志愿时数", field: "totalSeconds" },
      ];
    },
    excelData() {
      return this.selectedRowList.length
        ? this.selectedRowList
        : this.allTableData;
    },
    timeMoneyTableColumns() {
      return [
        { dataIndex: "name", title: "活动/商品名称", align: "center" },
        {
          dataIndex: "time",
          title: "操作时间",
          align: "center",
          scopedSlots: { customRender: "time" },
        },
        {
          dataIndex: "timeMoney",
          title: "时间币",
          align: "center",
          scopedSlots: { customRender: "timeMoney" },
        },
        { dataIndex: "admin", title: "管理员", align: "center" },
      ];
    },
    tableColumns() {
      return [
        { dataIndex: "nickName", title: "用户名", align: "center" },
        { dataIndex: "phone", title: "手机号", align: "center" },
        {
          dataIndex: "userType",
          title: "用户类型",
          align: "center",
          scopedSlots: { customRender: "userType" },
        },
        {
          dataIndex: "sex",
          title: "性别",
          align: "center",
          scopedSlots: { customRender: "sex" },
        },
        {
          dataIndex: "timeMoney",
          title: "时间币余额",
          align: "center",
          scopedSlots: { customRender: "timeMoney" },
        },
        {
          dataIndex: "totalSeconds",
          title: "志愿时数",
          align: "center",
          scopedSlots: { customRender: "totalSeconds" },
        },
        {
          title: "操作",
          align: "center",
          scopedSlots: { customRender: "action" },
        },
      ];
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  font-size: 22px;
}
.date-picker {
  display: flex;
  align-items: center;
}

.ant-card-body {
  padding: 0.56rem 1.06rem;
}
.ant-tabs-bar {
  margin: 0;
}

.ant-tabs-tab {
  border-radius: none !important;
}

.ant-table-row-cell-break-word {
  text-align: center !important;
}

.pagination {
  display: flex;
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.actions .label {
  display: flex;
  align-items: center;
}
.actions .btns {
  display: flex;
  justify-content: space-between;
}
.excel-btn {
  padding: 0 !important;
  border: 0 !important;
  background-color: transparent !important;
  cursor: pointer;
  vertical-align: middle;
}
.excel-btn:after {
  top: 0;
  left: 0;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.ant-tabs-tab {
  margin-right: 0;
}

.search-card {
  margin-bottom: 10px;
}
.time-coin-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.qrcode {
  display: flex;
  justify-content: center;
}
.modal-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  .modal-label {
    width: 150px;
    text-align: right;
  }
  .modal-upload {
    span {
      display: flex;
      align-items: center;
      ::v-deep
        .ant-upload-list-item-list-type-text.ant-upload-list-item.ant-upload-list-item-done {
        margin-top: 0;
        width: 200px;
        padding-left: 10px;
        .ant-upload-list-item-card-actions {
          display: none !important;
        }
      }
    }
  }
}
</style>